import React from "react";

import { Helmet } from "react-helmet";

const SiteMetadata: React.FC = () => (
  <Helmet>
    <title>Daniel Chao</title>
    <meta charSet="utf-8" />
    <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
    <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
    <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
    <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#000855" />
    <link rel="shortcut icon" href="/favicon.ico?v=2" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta name="theme-color" content="#000855" />
    <meta name="msapplication-TileColor" content="#000855" />

    <link rel="manifest" href="/manifest.json" />
  </Helmet>
);

export default SiteMetadata;
