import React from "react";
import { Link } from "gatsby";

import styles from "./index.module.css";

const NavigationLink: React.FC<{ to: string; text: string }> = ({ to, text }) => {
  return (
    <Link to={to} activeClassName={styles.link_active} className={styles.link}>
      <li className={styles.listItem}>{text}</li>
    </Link>
  );
};

export default NavigationLink;
