import React from "react";

import logo from "./logo.svg";
import styles from "./Logo.module.css";

const Logo: React.FC = () => {
  return <img src={logo} className={styles.logo} alt="DC" />;
};

export default Logo;
