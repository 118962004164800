import React from "react";

import NavigationLink from "./NavigationLink";

import styles from "./index.module.css";

const Navigation = () => (
  <nav>
    <ul className={styles.navigationList}>
      <NavigationLink to="/" text="About Me" />
      <NavigationLink to="/projects" text="Projects" />
      <NavigationLink to="/experience" text="Experience" />
      {/* <NavigationLink to="/skills" text="Skills" /> */}
    </ul>
  </nav>
);

export default Navigation;
