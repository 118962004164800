import React from "react";

import styles from "./index.module.css";

import SiteMetadata from "../SiteMetadata";
import Logo from "../Logo";
import SocialLinks from "../SocialLinks";
import Navigation from "../Navigation";

const Layout: React.FC<React.ReactNode> = ({ children }) => (
  <React.Fragment>
    <SiteMetadata />
    <div className={styles.container}>
      <div className={styles.sidebar}>
        <Logo />
        <SocialLinks />
        <Navigation />
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  </React.Fragment>
);

export default Layout;
